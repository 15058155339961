import BookIcon from '@mui/icons-material/Book';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { lazy, Suspense, useState } from 'react';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './Footer';
import logo from './Kahani Express_transparent-.png'; // Corrected path for the logo

// Lazy loading components for better performance
const AboutUs = lazy(() => import('./AboutUs'));
const StorySelector = lazy(() => import('./StorySelector'));
const StoryViewer = lazy(() => import('./StoryViewer'));
const Home = lazy(() => import('./Home')); // Import the Home component

const drawerWidth = 240;

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Router>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            display: { sm: 'none' }, // Hide the button on desktop
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' }, // Show only on mobile
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#f9f9f9',
            },
          }}
        >
          {drawerContents(handleDrawerToggle)}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' }, // Show only on desktop
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#f9f9f9',
            },
          }}
          open
        >
          {drawerContents()}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: '#ffffff', // White background for content area
            padding: '20px',
            marginLeft: { sm: `${drawerWidth}px` },
          }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} /> {/* Home is now the root route */}
              <Route path="/about" element={<AboutUs />} />
              <Route path="/stories" element={<StorySelector />} />
              <Route path="/story/:storyId" element={<StoryViewer />} />
            </Routes>
          </Suspense>
          <Footer />
        </Box>
      </Box>
    </Router>
  );
}

function drawerContents(handleDrawerToggle) {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
        <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }} onClick={handleDrawerToggle}>
          <img src={logo} alt="Kahani Express Logo" style={{ width: '80%', height: 'auto' }} />
        </Link>
      </Box>
      <List>
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <HomeIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <InfoIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="About Us" sx={{ color: '#3a3a3a' }} />
        </ListItem>
        
        <ListItem button component={Link} to="/stories" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <BookIcon sx={{ color: '#3a3a3a' }} />
          </ListItemIcon>
          <ListItemText primary="Stories" sx={{ color: '#3a3a3a' }} />
        </ListItem>
      </List>
    </Box>
  );
}

export default App;
